import { Link } from 'react-router-dom';
import Navbar from 'react-bootstrap/Navbar';
const Footer = () => {
  return <footer className="footer bg-dark">
  <div className="container">
    <Navbar fixed="top" className="w-100 px-md-5 pe-4 me-1 d-flex justify-content-end">
		<Link className="p-1 p-md-2 m-1 bg-light rounded" to="/pricing" aria-label="Pricing">Pricing</Link>
    <a className="p-1 p-md-2 m-md-1 bg-light rounded" href="/get-started">Get Started</a>
		<a className="p-1 p-md-2 m-md-1 bg-light rounded" href="/get-your-golden-ticket">CXOs</a>
		<a className="p-1 p-md-2 m-md-1 bg-light rounded" href="/nomad-camp-take-it-easy"><span className="d-none d-md-block">Nomad Camp</span><span className="d-md-none">Nomads</span></a>
		<a className="p-1 p-md-2 m-md-1 bg-light rounded" href="/laas-im-a-dreamer"><span className="d-md-none">LaaS</span><span className="d-none d-md-block">Leadership-As-A-Service</span></a>
    </Navbar>
    <Navbar fixed="bottom" className="w-100 text-end px-md-5">
		<a className="ms-5 p-1 p-md-2 m-md-1 bg-light rounded" href="/affiliates">Affiliates</a>
		<a className="p-1 p-md-2 m-md-1 bg-light rounded" href="/terms">Terms</a>
		<a className="p-1 p-md-2 m-md-1 bg-light rounded" href="/privacy">Privacy</a>
    <p className="w-100 text-black pe-5"><a href="https://raddrick.github.io/" target="raddrick" className="bg-light p-1 rounded">Rick Graham</a> / <a href="https://raddstudio.com" className="bg-light p-1 rounded">RaddStudio.com</a><br /> <span className="bg-light p-1 rounded">©'007-{new Date().getFullYear()}</span></p>
    </Navbar>
  </div>
</footer>
  
}
export default Footer;
